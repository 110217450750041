<template>
  <!-- navbar -->
  <section>
    <div class="container mx-auto bg-white-500">
      <!-- desktop navbar -->
      <nav class="xl:flex justify-between" id="navbar">
        <div class="relative">
          <div class="absolute xl:top-0 xl:left-0 lg:top-0 md:top-2/4 md:left-2/4 top-2/4 left-2/4 z-30 bg-white-500 rounded-b-lg shadow-xl main-logo" id="logoImage">
            <img src="/assets/images/RemoteLogo.png" class="w-80 px-12 pb-1.5 pt-5 max-w-none" alt="logo" />
          </div>
        </div>

        <div class="xl:hidden flex pt-4">
          <button class="bg-primary py-2 px-3 mx-auto rounded-full border border-stone-500-500 shadow-lg dark:shadow-secondary dark:text-white font-bold toggle-btn z-30" @click="togglebtn">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        <div class="hidden xl:flex" id="toggleMenu">
          <ul class="xl:flex lg:flex py-11 block text-left">
            <li class="px-11">
              <a href="#live_stream" class="uppercase nav-link text-blue-500 text-xs pb-2" :class="[getCurrentHash === '#live_stream' && 'active']">Live Stream</a>
            </li>
            <li class="px-11 xl:pt-0 lg:pt-0 pt-4">
              <a href="#photos" class="uppercase nav-link text-blue-500 text-xs pb-2" :class="[getCurrentHash === '#photos' && 'active']">Photos</a>
            </li>
            <li class="px-11 xl:pt-0 lg:pt-0 pt-4">
              <a href="#message_condolences" class="uppercase nav-link text-blue-500 text-xs pb-2" :class="[getCurrentHash === '#message_condolences' && 'active']">Message & condolences</a>
            </li>
            <li class="px-11 xl:pt-0 lg:pt-0 pt-4"><a href="#" class="uppercase nav-link text-blue-500 text-xs pb-2">support</a></li>
            <li class="px-11 xl:pt-0 lg:pt-0 pt-4"><a href="#" class="uppercase nav-link text-blue-500 text-xs pb-2">Donations</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <!-- ./end navbar -->
</template>
<script>
export default {
  computed: {
    getCurrentHash() {
      return this.$route.hash;
    },
  },
  methods: {
    togglebtn() {
      var dropdown = document.getElementById('toggleMenu');
      var logo = document.getElementById('logoImage');
      dropdown.classList.toggle('hidden');
      logo.classList.toggle('hidden');
    },
  },
};
</script>
