<template>
  <div>
    <!-- footer -->
    <section class="bg-blue-500 w-full mt-24">
      <div class="container mx-auto grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 md:grid-rows-1 grid-rows-2 xl:grid-rows-1" id="Footer">
        <div>
          <img src="/assets/images/RemoteRespects_Logo/FullLogo/White/RemoteRespects_LG_FullLogo_White.png" class="w-80 px-12 mx-auto" alt="footer-logo" />
        </div>
        <div class="xl:col-span-3 lg:col-span-3 md:col-span-3 xl:flex lg:flex md:flex block place-items-center justify-end pb-4 xl:pb-0 lg:pb-0 md:pb-0">
          <ul class="xl:flex lg:flex md:flex text-center">
            <li class="xl:px-6 lg:px-6 md:px-3 px-3">
              <a href="#live_stream" class="text-xs pb-2 uppercase footer-link text-white-500 font-Alegreya-light" :class="[getCurrentHash === '#live_stream' && 'active']">Live Stream</a>
            </li>
            <li class="xl:px-6 lg:px-6 md:px-3 px-3">
              <a href="#photos" class="text-xs pb-2 uppercase footer-link text-white-500 font-Alegreya-light" :class="[getCurrentHash === '#photos' && 'active']">photos</a>
            </li>
            <li class="xl:px-6 lg:px-6 md:px-3 px-3">
              <a href="#message_condolences" class="text-xs pb-2 uppercase footer-link text-white-500 font-Alegreya-light" :class="[getCurrentHash === '#message_condolences' && 'active']"
                >message & condolence</a
              >
            </li>
            <li class="xl:px-6 lg:px-6 md:px-3 px-3">
              <a href="#" class="text-xs pb-2 uppercase footer-link text-white-500 font-Alegreya-light">support</a>
            </li>
            <li class="xl:px-6 lg:px-6 md:px-3 px-3">
              <a href="#" class="text-xs pb-2 uppercase footer-link text-white-500 font-Alegreya-light">donations</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="bg-black-500 w-full">
      <div class="container mx-auto py-7 xl:grid xl:grid-cols-2 lg:grid lg:grid-cols-2 md:flex block md:px-8">
        <div class="flex place-items-center justify-center xl:justify-start lg:justify-start md:justify-start">
          <p class="text-10x text-white-500 uppercase whitespace-nowrap">
            All rights reserved. Copyright
            {{ currentYear }}
            Remote Respects.
          </p>
        </div>
        <div class="xl:flex lg:flex md:flex xl:justify-end lg:justify-end md:justify-end text-center place-items-center">
          <p class="text-10x text-white-500 uppercase">Powered By</p>
          <img src="/assets/images/iSiLIVE_LG.png" class="w-1/5 mx-auto xl:mx-0 lg:mx-0 md:mx-0" alt="footer-logo(1)" />
        </div>
      </div>
    </section>
    <!-- ./end footer -->
  </div>
</template>
<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    getCurrentHash() {
      return this.$route.hash;
    },
  },
};
</script>