<template>
  <!-- accordion -->
  <section id="accordions" class="pt-11 px-4 xl:px-0 lg:px-4 md:px-8">
    <div class="container mx-auto">
      <div class="shadow-2xl rounded-xl xl:p-16 lg:p-16 md:p-16 p-10">
        <h3 class="text-black-500 text-18x">Have Questions?</h3>
        <h1 class="text-34x text-blue-500 font-Averia-Italic">Find Answers Here</h1>
      </div>

      <Accordion>
        <AccordionItem v-for="(question, index) in questions" :key="index" class="mt-2">
          <template #title="{ visible }">
            <div :class="[visible && 'bg-blue-500 text-white']" class="accordion-header py-8 xl:px-16 lg:px-16 md:px-16 px-10 cursor-pointer shadow-xl rounded-xl text-2xl">
              {{ question.question }}
            </div>
          </template>
          <div slot="content" class="accordion-body py-12 xl:px-16 lg:px-16 md:px-16 px-10 bg-white-500 shadow-md">
            <p class="text-23x text-black-500 font-Alegreya-light">
              {{ question.answer }}
            </p>
            <!-- <p class="text-23x text-black-500 font-Alegreya-light pt-5">
              {{ question.commentor }}
            </p> -->
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  </section>
  <!-- ./end accordion -->
</template>
<script>
import { get } from 'lodash';
import Accordion from '@/components/Accordion';
import AccordionItem from '@/components/Accordion/item';
import { mapState } from 'vuex';

export default {
  components: {
    Accordion,
    AccordionItem,
  },
  computed: {
    ...mapState({
      event: 'event',
    }),
    questions() {
      return get(this.event, 'questionAnswers', []);
    },
  },
};
</script>
