import { extend, localize } from 'vee-validate';
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
  min_value,
  max_value,
} from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required);

export const email = extend('email', rule_email);

const validatorPositive = value => value;
export const agree = extend('agree', {
  validate: validatorPositive,
  message: 'Please agree terms & conditions!',
});

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
});
