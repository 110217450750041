<template>
  <!-- testimonial -->
  <section class="pt-12 px-4 xl:px-0 lg:px-4 md:px-8">
    <div class="container mx-auto">
      <div class="shadow-2xl rounded-xl xl:p-16 lg:p-16 md:p-16 p-10">
        <h3 class="text-black-500 text-18x">A Message</h3>
        <h1 class="text-34x text-blue-500 font-Averia-Italic">From The Family</h1>
        <p class="text-23x text-black-500 font-Alegreya-light pt-11">
          {{ event.messageFromFamily }}
        </p>
      </div>
      <div class="shadow-2xl rounded-b-xl xl:p-16 lg:p-16 md:p-16 p-10" v-if="getMessageItems.length > 0">
        <!-- slide one -->
        <div class="testimonial-slides">
          <div v-for="(item, index) in getMessageItems" :key="index" class="border-b border-stone-500">
            <p class="text-23x font-Alegreya-light text-black-500">
              {{ item.message }}
            </p>
            <h3 class="text-23x text-black-500 pt-11 pb-14">{{ item.fullName }}</h3>
          </div>
        </div>

        <div v-if="hasNext" class="flex justify-center pt-11">
          <!-- button previous & next -->
          <button class="w3-button w3-black w3-display-left border-2 rounded-full p-1 border-blue-500 mx-1 transform hover:scale-125 transition duration-150" @click="testimonialButton(-1)">
            <img src="/assets/images/left-arrow.svg" class="h-7" alt="left-arrow" />
          </button>
          <button class="w3-button w3-black w3-display-right border-2 rounded-full p-1 border-blue-500 mx-1 transform hover:scale-125 transition duration-150" @click="testimonialButton(1)">
            <img src="/assets/images/right-arrow.svg" class="h-7" alt="right-arrow" />
          </button>
        </div>
      </div>
    </div>
  </section>
  <!-- ./end testimonial -->
</template>

<script>
import { slice, orderBy } from 'lodash';
import { mapState } from 'vuex';
import EventService from '@/services/EventService';
export default {
  data: () => ({
    currentIndex: 0,
    isLoading: false,
    messageItems: [],
  }),
  computed: {
    ...mapState({
      event: 'event',
    }),
    getMessageItems() {
      return slice(this.messageItems, this.currentIndex * 2, this.currentIndex * 2 + 2);
    },
    hasNext() {
      return this.messageItems.length > 2;
    },
  },
  mounted() {
    this.doGetCondolences();
  },
  methods: {
    testimonialButton(pos) {
      let current = this.currentIndex;
      current += pos;
      if (current > this.messageItems.length / 2) {
        current = 0;
      }
      if (current < 0) {
        current = this.messageItems.length / 2;
      }
      this.currentIndex = current;
    },
    async doGetCondolences() {
      try {
        console.log('doGetCondolences');
        this.isLoading = true;
        const resp = await EventService.getCondolences(this.event.id);
        this.messageItems = orderBy(resp.data, ['lastUpdatedDate'], ['desc']);
      } catch (error) {
        console.log({ error });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
