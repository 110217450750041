<template>
  <!-- video section -->
  <section class="pb-6 px-4 xl:px-0 lg:px-4 md:px-8 xl:mb-6" id="live_stream">
    <div class="container mx-auto shadow-xl bg-white-500 rounded-xl" id="videoPlayer">
      <div class="bg-white-500 rounded-t-md xl:flex lg:flex md:flex place-items-center mt-20">
        <div class="bg-white-500 rounded-lg shadow-xl m-8">
          <img :src="getHomeLogo" class="px-12 max-h-32 mx-auto" alt="logo" />
        </div>
        <div class="text-center md:text-left lg:text-left">
          <h3 class="text-18x text-blue-500 uppercase">Arranged With</h3>
          <h2 class="text-34x text-blue-500 font-Averia-Italic">{{ event.homeName }}</h2>
        </div>
      </div>

      <div class="flex place-items-center justify-center rounded-t-lg overflow-hidden relative h-54vw md:h-50rem">
        <iframe
          id="videoScreen"
          :src="youtubeUrlWithParams"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
          allowfullscreen
          align-self
        />
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import { get } from 'lodash';
import { objToQueryString } from '@/helpers/misc.js';
export default {
  computed: {
    ...mapState({
      event: 'event',
    }),
    getHomeLogo() {
      return get(this.event, 'homeLogo', '/assets/images/vedio-logo.png');
    },
    youtubeUrlWithParams() {
      const params = {
        autoplay: '1',
        controls: '0',
        iv_load_policy: '3',
        modestbranding: '1',
        rel: '0',
      };
      return this.event.youtubeURL + '?' + objToQueryString(params);
    },
  },
};
</script>
<style lang="scss">
#live_stream {
  iframe {
    @apply flex-1 block w-full h-full rounded-b-xl;
  }
  .videoHeight {
    @apply flex-none block;
  }
}
</style>
