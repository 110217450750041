<template>
  <!-- slider -->
  <section class="px-4 xl:px-0 lg:px-4 md:px-8" id="photos">
    <div
      class="container mx-auto grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-4 grid-cols-4 xl:grid-rows-1 lg:grid-rows-1 grid-rows-2 xl:grid-flow-row lg:grid-flow-row shadow-2xl rounded-2xl xl:pt-16 lg:pt-16 md:pt-16 pt-6 xl:px-16 lg:px-16 md:px-16 px-6 bg-white-500 gap-6 pb-16"
    >
      <div class="slider-images col-span-4 relative rounded-2xl">
        <div
          class="slides-bg-img bg-cover bg-no-repeat h-full rounded-2xl bg-center"
          :style="{
            backgroundImage: `url(${galleryItems[currentImageIndex].src})`,
          }"
        />

        <!-- next & previous button -->
        <div class="absolute bottom-0 left-2/4" id="nextPreviousBtn">
          <a class="prev" @click="sliderButton(-1)">
            <img src="/assets/images/buttonleft-noshadow.png" class="inline-block shadow-lg rounded-full cursor-pointer transform hover:scale-125 transition duration-150" alt="btn-left" />
          </a>
          <a class="next ml-5" @click="sliderButton(1)">
            <img src="/assets/images/buttonright-noshadow.png" class="inline-block shadow-lg rounded-full cursor-pointer transform hover:scale-125 transition duration-150" alt="btn-right" />
          </a>
        </div>
      </div>
      <div class="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-5 grid-cols-5 xl:gap-6 lg:gap-6 md:gap-6 gap-2 col-span-4 xl:col-auto lg:col-auto md:mt-10 xl:mt-0 lg:mt-0">
        <div v-for="(item, index) in galleryItems" :key="index" class="column-img flex place-items-center cursor-pointer justify-center" @click="currentImageIndex = index">
          <img :src="item.src" class="select-img p-2 w-full rounded-xl" :class="[currentImageIndex === index && 'active']" alt="slide" />
        </div>
      </div>
    </div>
  </section>
  <!-- ./end slider -->
</template>
<script>
import { mapState } from 'vuex';
import { get, map } from 'lodash';
export default {
  data: () => ({
    currentImageIndex: 0,
  }),
  computed: {
    ...mapState({
      event: 'event',
    }),
    galleryItems() {
      return map(get(this.event, 'deceasedGallery', []), src => ({
        src,
      }));
    },
  },
  methods: {
    sliderButton(pos) {
      let current = this.currentImageIndex;
      current += pos;
      if (current > this.galleryItems.length - 1) {
        current = 0;
      }
      if (current < 0) {
        current = this.galleryItems.length - 1;
      }
      this.currentImageIndex = current;
    },
  },
};
</script>