import moment from 'moment';
import { isNumber, isString } from 'lodash';

export function formatDateUnix(value, format = 'YYYY/MM/DD') {
  if (isNumber(value)) {
    return moment(value).format(format);
  }
}

export function formatTimeUnix(value, isGetSecond = false) {
  if (isNumber(value)) {
    return moment(value).format(isGetSecond ? 'HH:mm:ss' : 'HH:mm');
  }
}

export function formatDate(value, format = "'YYYY/MM/DD'") {
  if (value) {
    return moment(new Date(String(value))).format(format);
  }
}

export function formatDateTime(value, format = 'YYYY-MM-DD HH:mm') {
  if (value) {
    return moment(isString(value) ? new Date(String(value)) : value).format(format);
  }
}

export function formatTime(value, isGetSecond = false) {
  if (value) {
    return moment(new Date(String(value))).format(isGetSecond ? 'HH:mm:ss' : 'HH:mm');
  }
}
