<template>
  <div class="bg-[#f0f]">
    <NavBar />
    <Banner />
    <VideoSection />
    <Remembering />
    <Slider />
    <Condolences />
    <Testimonial ref="Testimonial" />
    <QA />
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/Navbar';
import Banner from '@/components/Banner';
import VideoSection from '@/components/VideoSection';
import Remembering from '@/components/Remembering';
import Slider from '@/components/Slider';
import Condolences from '@/components/Condolences';
import Testimonial from '@/components/Testimonial';
import QA from '@/components/QA';
import Footer from '@/components/Footer';
import { empty } from '@/utils';
export default {
  components: {
    NavBar,
    Banner,
    VideoSection,
    Remembering,
    Slider,
    Condolences,
    Testimonial,
    QA,
    Footer,
  },
  mounted() {
    setTimeout(() => {
      if (!empty(this.$route.hash)) {
        const currentEle = document.getElementById(this.$route.hash.substring(1));
        if (currentEle) {
          currentEle.scrollIntoView();
        }
      }
    }, 300);
  },
};
</script>
