<template>
  <!-- remembering -->
  <section class="pb-11 xl:px-0 px-4 lg:px-4 md:px-8" id="funeralRemember">
    <div class="container mx-auto mt-9 md:mt-24 lg:mt-0 xl:mt-0 xl:flex lg:flex md:block block justify-between">
      <div class="shadow-2xl rounded-lg xl:w-3/12 lg:w-3/12 md:w-full w-full overflow-hidden">
        <div class="rounded-lg">
          <img :src="getPersonalImage" class="w-full" :alt="event.deceasedFullName" />
        </div>
        <div class="text-center px-6 pt-6 pb-8">
          <h4 class="text-18x uppercase text-black-500">In Memory Of</h4>
          <h2 class="text-34x capitalize text-blue-500 font-Averia-Italic">{{ event.deceasedFullName }}</h2>
          <p class="text-sm text-black-500">({{ getBirthDay }} - {{ getDeathDay }})</p>
        </div>
      </div>

      <div class="shadow-2xl xl:w-9/12 lg:w-9/12 md:w-full xl:ml-12 lg:ml-12 w-full rounded-xl xl:p-16 lg:p-16 md:p-16 p-10 mt-8 xl:mt-0 lg:mt-0">
        <h4 class="text-18x text-black-500 uppercase">Remembering</h4>
        <div class="xl:flex lg:flex md:flex place-items-center pb-11">
          <h2 class="font-Averia-Italic text-blue-500 text-34x capitalize">{{ event.deceasedFullName }}</h2>
          <button class="bg-blue-500 rounded-full text-white-500 text-sm py-1 h-fit xl:px-5 lg:px-5 md:px-5 px-3 xl:ml-7 lg:ml-7 md:ml-7">{{ getBirthDay }} - November 23, 2020</button>
        </div>
        <p class="text-18x text-blue-500 font-Averia-Italic border-b border-gray-500 pb-11">“{{ event.ObituaryText }}” - {{ event.deceasedFullName }}</p>
        <p class="text-sm text-black-500 font-Alegreya-light pb-8 pt-11">
          {{ event.messageFromFamily }}
        </p>
      </div>
    </div>
  </section>
  <!-- ./end remembering -->
</template>
<script>
import { mapState } from 'vuex';
import { formatDate } from '@/helpers/date';
import { get } from 'lodash';
export default {
  computed: {
    ...mapState({
      event: 'event',
    }),
    getBirthDay() {
      return formatDate(this.event.birthDate, 'MMMM DD, YYYY');
    },
    getDeathDay() {
      return formatDate(this.event.deathDate, 'MMMM DD, YYYY');
    },
    getPersonalImage() {
      return get(this.event, 'deceasedPersonImage', '/assets/images/vedio-logo.png');
    },
  },
};
</script>
