<template>
  <!-- banner -->
  <section
    class="bg-no-repeat bg-cover"
    :style="{
      backgroundImage: `url('/assets/images/Header_Background.png')`,
    }"
  >
    <div class="container mx-auto">
      <div class="text-center pt-72 xl:pt-32 lg:pt-32 xl:pb-80 lg:pb-80 md:pb-72 pb-32">
        <h3 class="uppercase text-26x text-white-500">in memory of</h3>
        <h1 class="capitalize text-59x font-Averia-Italic text-white-500">{{ event.deceasedFullName }}</h1>
        <h3 class="text-23x text-white-500">({{ getBirthDay }} - {{ getDeathDay }})</h3>
      </div>
    </div>
  </section>
  <!-- ./end banner -->
</template>
<script>
import { mapState } from 'vuex';
import { formatDate } from '@/helpers/date';
export default {
  computed: {
    ...mapState({
      event: 'event',
    }),
    getBirthDay() {
      return formatDate(this.event.birthDate, 'MMMM DD, YYYY');
    },
    getDeathDay() {
      return formatDate(this.event.deathDate, 'MMMM DD, YYYY');
    },
  },
};
</script>
